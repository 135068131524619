import React from 'react'
import Layout from '../Layout/Layout'

const f1 = () => {
    return (
        <div>
            {/* <Layout /> */}
        </div>
    )
}

export default f1