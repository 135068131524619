import React from 'react';

const TermsofService = () => {
    return (
        <>
            <div className="commonBanner-wrapper terms">
                <div className="common-banner">
                    <h1 className="our-team-heading">Terms of Service</h1>
                </div>
            </div>
            <div className="common-banner">
                <h2 className="our-team-heading" style={{color: "#3D76C1"}}>Terms of Service</h2>
                <p className="our-team-caption">Terms of Servic</p>
            </div>

        </>
    )
}

export default TermsofService
